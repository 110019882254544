// @ts-nocheck
/* eslint-enable */
import searchSlugUtils from 'app/shared/utils/searchSlugUtils';
import appUtils from 'app/shared/utils/appUtils';
import states from 'app/shared/utils/statesObject.json';

const getUSStateData = (areaId) => {
  // Check if the United Sates JSON object contains areaId
  const isInUnitedStatesObject = states.hasOwnProperty(areaId);
  const stateResourceId = isInUnitedStatesObject ? states[areaId].resourceId : null;
  const stateName = isInUnitedStatesObject ? states[areaId].name : null;
  const stateAbbreviation = isInUnitedStatesObject ? states[areaId].abbreviation : null;

  return {
    stateResourceId,
    stateName,
    stateAbbreviation,
  };
};

const getLinkText = (breadcrumbArea) => {
  if (breadcrumbArea.type === 'state') {
    const { stateAbbreviation, stateName } = getUSStateData(breadcrumbArea.id);

    if (stateAbbreviation) {
      // Use the resourceId is link text and the stateName is aria text
      return { linkText: stateAbbreviation, ariaText: stateName };
    }
  }
  return { linkText: breadcrumbArea.name };
};

const getAreaUri = (breadcrumbArea, searchSlug) => {
  if (breadcrumbArea.type === 'state') {
    const { stateResourceId } = getUSStateData(breadcrumbArea.id);

    if (stateResourceId) {
      return '/' + stateResourceId;
    }
  }

  return '/' + breadcrumbArea.resourceId + '/' + searchSlug;
};

const formattedCrumb = (breadcrumbArea, searchSlug) => {
  if (breadcrumbArea.type === 'metroarea') {
    return false;
  } else {
    const areaUri = getAreaUri(breadcrumbArea, searchSlug);
    const { linkText, ariaText = null } = getLinkText(breadcrumbArea);
    return {
      name: linkText,
      aria: ariaText,
      uri: areaUri,
    };
  }
};

const useBreadcrumbs = (areaCrumbs, currentListingCrumbs, searchSlug) => {
  const crumbArray = [];
  const filterDescription = searchSlugUtils.prepareSlugDescription(searchSlug);

  const createCrumbs = (crumbs, slug) => {
    crumbs.forEach((crumb) => {
      const createdCrumb = formattedCrumb(crumb, slug);
      if (createdCrumb) {
        crumbArray.push(createdCrumb);
      }
    });
  };

  if (currentListingCrumbs) {
    createCrumbs(currentListingCrumbs, searchSlug);
  } else if (Array.isArray(areaCrumbs)) {
    createCrumbs(areaCrumbs, searchSlug);
  }

  crumbArray.forEach((breadcrumb, i, arr) => {
    arr[i] = appUtils.remapPropertyLinks(breadcrumb);

    // only add property type for last breadcrumb
    if (i === crumbArray.length - 1) {
      let appendText = filterDescription.match(/.+(?=for)/) && filterDescription.match(/.+(?=for)/)[0];
      let hasWithAmenity = filterDescription.match(/(with).*$/) && filterDescription.match(/(with).*$/)[0];

      if (!appendText) {
        // default for slugs that start with 'for', ex: `for-rent-by-apartments`
        appendText = 'rentals';
      }

      appendText = appendText.trim();
      arr[i].name += ' ' + appendText;

      if (hasWithAmenity) {
        hasWithAmenity = hasWithAmenity.trim();
        arr[i].name += ' ' + hasWithAmenity;
      }
    }
  });

  return [crumbArray];
};

export default useBreadcrumbs;
