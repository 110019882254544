/* eslint-enable */
import styled from 'styled-components';
import { fontSize } from 'app/shared/styles/_fontsize';
import { padding } from 'app/shared/styles/_spacing';
import { colors } from 'app/shared/styles/_colors';

import Linker from 'app/shared/modules/Linker';

export const BreadcrumbNavContainer = styled.nav`
  ${padding.top._1x};
  ${padding.bottom._1x};
  min-height: 36px;
`;

export const BreadcrumbNavOrderedList = styled.ol`
  display: flex;
  list-style: none;
  line-height: 2;
  align-items: baseline;
`;

export const ListItem = styled.li`
  font-size: ${fontSize['$font-size-sm']};
  line-height: ${fontSize['$line-height-sm']};

  &:not(:last-of-type):after {
    display: inline-block;
    margin: 0 8px;
    transform: rotate(15deg);
    border-right: 1px solid ${colors['$hpx-grey-600']};
    height: 10px;
    content: '';
  }
`;

export const BreadcrumbLinker = styled(Linker)`
  &:focus {
    outline: -webkit-focus-ring-color auto 1px;
    outline-offset: 3px;
  }
  ${(props) =>
    props.isActive &&
    `
        font-weight: bold;
        color: ${colors['$hpx-blue-600']}
    `}
`;
